<!--
 * @Description    : 
 * @Version        : 1.0.0
 * @Author         : QianLong
 * @Date           : 2021-10-13 14:13:54
 * @LastEditors    : QianLong
 * @LastEditTime   : 2023-12-27 16:59:04
-->
<template>
  <div style="height: 100%; background: #ffffff">
    <div
      style="
        display: flex;
        padding: 15px;
        align-items: center;
        background: #f6edce;
      "
    >
      <img
        :src="pageData.platform.icon"
        :alt="pageData.platform.platform_title"
        srcset=""
        style="width: 39px"
      />
      <span
        style="
          color: #212015;
          font-size: 18px;
          font-weight: 500;
          margin-left: 11px;
        "
        >{{ pageData.platform.platform_title }}会员</span
      >
    </div>
    <div
      style="
        display: flex;
        padding: 15px;
        flex-direction: column;
        background: #fff;
        padding-top: 32px;
      "
    >
      <span style="color: #414141; font-size: 15px">会员账号</span>
      <van-field
        v-model="formData['account' + index]"
        :placeholder="item"
        v-for="(item, index) in pageData.platform.form"
        :key="index"
        clearable
        required
        style="font-size: 18px"
      />
    </div>
    <div
      style="
        display: flex;
        padding: 15px;
        flex-direction: column;
        background: #fff;
      "
      v-if="pageData.group_tag != undefined && pageData.group_tag.length > 0"
    >
      <span style="color: #414141; font-size: 15px">会员类型</span>
      <van-radio-group
        v-model="formData.type"
        direction="horizontal"
        style="margin-top: 20px"
        @change="changeType"
      >
        <van-radio
          :name="type.tag"
          v-for="(type, index) in pageData.group_tag"
          :key="index"
        >
          <template #icon="props">
            <van-button
              type="primary"
              style="
                background: #e7d7a3;
                color: #482d00;
                border: none;
                font-weight: 500;
              "
              v-if="props.checked"
              >{{ type.title }}</van-button
            >
            <van-button
              type="primary"
              style="
                background: #f6f5f0;
                color: #482d00;
                border: none;
                font-weight: 500;
              "
              v-if="!props.checked"
              >{{ type.title }}</van-button
            >
          </template>
        </van-radio>
      </van-radio-group>
    </div>
    <div
      style="
        display: flex;
        padding: 15px;
        flex-direction: column;
        background: #fff;
        padding-bottom: 40px;
      "
    >
      <span style="color: #414141; font-size: 15px; margin-bottom: 20px"
        >充值面额</span
      >
      <van-grid
        :column-num="3"
        :gutter="10"
        :border="false"
        style="background: #fff"
      >
        <van-grid-item
          v-for="(item, index) in items"
          :key="index"
          @click="selectItems(item)"
        >
          <template #default>
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #482d00;
                height: 100%;
                padding: 18px 0px;
                width: 100%;
              "
              :style="
                formData.item == item.code
                  ? 'background:#e7d7a3'
                  : 'background:#f6f5f0'
              "
            >
              <span style="font-weight: 500">{{ item.title }}</span>
              <span
                style="
                  font-size: 12px;
                  text-decoration: line-through;
                  margin: 6px;
                "
                >{{ item.denomination }}元</span
              >
              <span style="font-weight: 500">现{{ item.price }}元</span>
            </div>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <div
      style="
        display: flex;
        padding: 15px;
        flex-direction: column;
        background: #f7f7f7;
        width: 87%;
        margin-left: 3%;
      "
    >
      <span style="color: #414141; font-size: 15px"
        ><van-icon name="info-o" style="margin-right: 12px" />温馨提示</span
      >
      <div v-html="pageData.platform.tips" style="font-size: 13px"></div>
      <div style="font-size: 13px; color: red">
        <van-divider
          dashed
          style="color: #d6c397; bordercolor: #d6c397; margin: 0px"
          v-if="
            pageData.platform.tips != undefined &&
            pageData.platform.tips.length > 1
          "
        />
        <p>
          <van-icon name="warning-o" />
          请确定充值账号的正确性，虚拟商品，无法退货。
        </p>
        <p
          v-if="
            pageData.kf_setting.kf_phone != undefined &&
            pageData.kf_setting.kf_phone != null &&
            pageData.kf_setting.kf_phone.length > 6
          "
        >
          <van-icon name="warning-o" />
          如对本次充值相关内容有疑问，请拨打客服热线：<a
            :href="'tel:' + pageData.kf_setting.kf_phone"
            >{{ pageData.kf_setting.kf_phone }}</a
          >
        </p>
        <p v-else>
          <van-icon name="warning-o" />
          如对本次充值相关内容有疑问，请拨打客服热线：<a href="tel:053168961851"
            >0531-68961851</a
          >
        </p>
      </div>
    </div>
    <div
      style="
        display: flex;
        padding: 7px 0px 15px 0px;
        flex-direction: column;
        width: 100%;
        margin-bottom: 50px;
        text-align: center;
        color: #503508;
      "
    >
      <span style="color: #414141; font-size: 15px">© 喵有券</span>
    </div>
    <van-submit-bar
      button-text="立即充值"
      @submit="submitOrder"
      button-color="#e7d7a3"
      :loading="loading"
    >
      <template #default>
        <div style="display: flex; justify-content: flex-start; width: 70%">
          <div
            style="display: flex; align-items: center; color: #482d00"
            @click="kfUrl"
          >
            <van-icon name="service-o" size="28" />
            <span style="font-size: 16px; margin-left: 5px">在线客服</span>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              color: #482d00;
              margin-left: 25px;
            "
            @click="gotoOrder"
          >
            <van-icon name="orders-o" size="28" />
            <span style="font-size: 16px; margin-left: 5px">订单</span>
          </div>
        </div>
      </template>
      <!-- <template #tip>
        
      </template> -->
    </van-submit-bar>
    <van-action-sheet
      v-model:show="actionShow"
      title="选择支付方式"
      close-on-click-action
      @closed="payTypeClose"
    >
      <div class="content">
        <van-radio-group v-model="payType">
          <van-cell-group>
            <van-cell
              title="支付宝支付"
              clickable
              @click="postOrder('alipay')"
              title-style="font-size:15px;"
              icon="https://img.alicdn.com/imgextra/i4/573804794/O1CN01LSTSdY1lHhS7H10rK_!!573804794.png"
            >
              <template #right-icon>
                <van-radio name="alipay" />
              </template>
            </van-cell>
            <van-cell
              v-if="wxh5 === true"
              title="微信支付"
              clickable
              @click="postOrder('wxpay')"
              title-style="font-size:15px;"
              icon="https://img.alicdn.com/imgextra/i3/573804794/O1CN01NPHO231lHhRxnMwVD_!!573804794.png"
            >
              <template #right-icon>
                <van-radio name="wxpay"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
    </van-action-sheet>
    <van-dialog v-model:show="showKfQrcode" title="扫码添加客服">
      <img :src="pageData.kf_setting.kf_link" style="width: 300px; height: 300px; margin-left: 10px; margin-right: 10px;margin-top: 20px;" />
    </van-dialog>
    <van-loading size="24px" vertical color="#482d00" v-if="loading"
      >订单提交中...</van-loading
    >
    <div id="formDiv"></div>
  </div>
</template>

<script>
import {
  Grid,
  GridItem,
  Button,
  SubmitBar,
  Icon,
  Image as VanImage,
  Notify,
  Field,
  RadioGroup,
  Radio,
  Dialog,
  ActionSheet,
  Divider,
  Cell,
  CellGroup,
  Loading,
} from "vant";
import { get, post } from "@/utils/request";
import { reactive, toRefs, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
const { aplus_queue } = window;
import wx from "weixin-js-sdk";
window.wx = wx;

export default {
  components: {
    [SubmitBar.name]: SubmitBar,
    [Button.name]: Button,
    [Grid.name]: Grid,
    [Loading.name]: Loading,
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
    [Dialog.Component.name]: Dialog.Component,
    [GridItem.name]: GridItem,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Divider.name]: Divider,
    [ActionSheet.name]: ActionSheet,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  setup() {
    const formRef = ref();
    const router = useRouter();
    const state = reactive({
      loading: false,
      showKfQrcode: false,
      actionShow: ref(false),
      item_id: "",
      formData: {
        type: "",
        open_id: ""
      },
      items: [],
      payType: "",
      pageData: {
        platform: {
          platform_title: "",
        },
        kf_setting: {
          kf_phone: "",
          kf_link: "",
        },
      },
      index: 0,
      active: 0,
      state: {
        smsSendBtn: false,
        time: 60,
      },
      allMenuData: [],
      visible: false,
      searchType: "0",
      searchTitle: "",
      operateTitle: "编辑",
      wxminiapp: false,
      wxh5: false
    });
    onMounted(() => {
      const item_id = router.currentRoute.value.query.id;
      const token = router.currentRoute.value.query.token;
      // const openid = router.currentRoute.value.query.openid;
      // let open_id = ''
      if (token !== undefined && token.length > 20) {
        localStorage.token = token
      }
      // if (openid !== undefined && openid.length > 20) {
      //   localStorage.openid = openid
      //   open_id = openid
      // }else{
      //   open_id = localStorage.getItem('openid')
      // }
      // if (typeof window.WeixinJSBridge === 'object' && typeof window.WeixinJSBridge.invoke === 'function' && (open_id == undefined || open_id.length < 10)) {
      //   window.location.href = 'https://21ds.cn/mp_weixin/oauth?mw_id=MW4754391651808828&scope=snsapi_base&redirect_uri='+encodeURIComponent(window.location.href)
      // }
      state.item_id = item_id;
      getIndexData(item_id);
      if (typeof window.WeixinJSBridge === 'object' && typeof window.WeixinJSBridge.invoke === 'function') {
        state.wxh5 = true
      } else {
        state.wxh5 = false
      }
      wx.miniProgram.getEnv(function (res) {
            if (res.miniprogram) {
              state.wxminiapp = true
            }
          })
    });
    function gotoOrder() {
      router.push("/order?id=" + state.item_id);
    }
    function getIndexData(id) {
      get("getItem", { id: id }).then((response) => {
        if (response.code == 200) {
          state.pageData = response.data;
          if (
            response.data.group_tag == undefined ||
            response.data.group_tag.length == 0
          ) {
            state.items = state.pageData.items;
          } else {
            state.formData.type = response.data.group_tag[0].tag;
            state.items = state.pageData.items[state.formData.type];
          }
          localStorage.pv_user_id = response.data.pv_user_id;
          localStorage.pv_user_name = response.data.pv_user_name;
          aplus_queue.push({
            action: "aplus.sendPV",
            arguments: [
              {
                is_auto: false,
              },
              {
                pv_user_id: response.data.pv_user_id,
                pv_user_name: response.data.pv_user_name,
              },
            ],
          });
        } else {
          Notify({ type: "danger", message: response.msg });
          return false;
        }
      });
    }
    function changeType(e) {
      state.items = state.pageData.items[e];
      aplus_queue.push({
        action: "aplus.record",
        arguments: [
          "itemSkuTab",
          "CLK",
          {
            sku_code: e,
            platform_title: state.pageData.platform.platform_title,
            pv_user_id: state.pageData.pv_user_id,
            pv_user_name: state.pageData.pv_user_name,
          },
        ],
      });
    }
    function kfUrl() {
        if (
          state.pageData.kf_setting.kf_link != undefined &&
          state.pageData.kf_setting.kf_link != null &&
          state.pageData.kf_setting.kf_link.length > 12
        ) {
          if(state.pageData.kf_setting.kf_type == 2){
state.showKfQrcode = true
          }else{
            window.location.href = state.pageData.kf_setting.kf_link;
          }
        } else {
          window.location.href =
            "https://work.weixin.qq.com/kfid/kfc46302394e78fedfb";
        }
    }
    function selectItems(item) {
      state.formData.item = item.code;
      state.formData.item_title = item.group_tag + "-" + item.title;
      aplus_queue.push({
        action: "aplus.record",
        arguments: [
          "itemSkusTab",
          "CLK",
          {
            item_title: item.group_tag + "-" + item.title,
            platform_title: state.pageData.platform.platform_title,
            pv_user_id: state.pageData.pv_user_id,
            pv_user_name: state.pageData.pv_user_name,
          },
        ],
      });
    }
    function payTypeClose() {
      state.payType = "";
    }
    function submitOrder() {
      if (
        state.formData.account0 == undefined ||
        state.formData.account0.length < 6
      ) {
        Notify({ type: "danger", message: "请输入完整会员账号" });
        return false;
      }
      if (state.formData.item == undefined || state.formData.item.length == 0) {
        Notify({ type: "danger", message: "请选择充值面额" });
        return false;
      }
      aplus_queue.push({
        action: "aplus.record",
        arguments: [
          "submitBtnTab",
          "CLK",
          {
            account: state.formData.account0,
            item_title: state.formData.item_title,
            platform_title: state.pageData.platform.platform_title,
            pv_user_id: state.pageData.pv_user_id,
            pv_user_name: state.pageData.pv_user_name,
          },
        ],
      });
      Dialog.confirm({
        title: "请确保充值账号无误，提交后无法退换",
        message: "充值账号：" + state.formData.account0,
        confirmButtonText: "确认账号并充值",
      })
        .then(() => {
          aplus_queue.push({
            action: "aplus.record",
            arguments: [
              "confirmBtnTab",
              "CLK",
              {
                account: state.formData.account0,
                item_title: state.formData.item_title,
                platform_title: state.pageData.platform.platform_title,
                pv_user_id: state.pageData.pv_user_id,
                pv_user_name: state.pageData.pv_user_name,
              },
            ],
          });
          //微信环境
          wx.miniProgram.getEnv(function (res) {
            if (res.miniprogram) {
              state.wxminiapp = true
              createOrder()
              return false
            }
          })
          if (!state.wxminiapp) {
            state.actionShow = true;
          }
        })
        .catch(() => {
          // on cancel
        });
    }
    function createOrder() {
      if (
        state.formData.account0 == undefined ||
        state.formData.account0.length == 0
      ) {
        Notify({ type: "danger", message: "请输入完整会员账号" });
        return false;
      }
      if (state.formData.item == undefined || state.formData.item.length == 0) {
        Notify({ type: "danger", message: "请选择充值面额" });
        return false;
      }
      state.loading = true;
      post("createOrder", state.formData)
        .then((response) => {
          if (response.code == 200) {
            wx.miniProgram.navigateTo({
                url: `/pages/ecapiPay/ecapiPay?orderNo=${response.data.orderNo}&money=${response.data.money}&user_id=${response.data.user_id}&title=${response.data.title}&pageUrl=${encodeURI(response.data.pageUrl)}`
              });
          }else{
            Dialog({ message: response.msg, title: '失败' });
            return false;
          }
          return false
        })
        .finally(() => {
          state.loading = false;
          return false
        })
        return false
    }
    function postOrder(payType) {
      if (
        state.formData.account0 == undefined ||
        state.formData.account0.length == 0
      ) {
        Notify({ type: "danger", message: "请输入完整会员账号" });
        return false;
      }
      if (state.formData.item == undefined || state.formData.item.length == 0) {
        Notify({ type: "danger", message: "请选择充值面额" });
        return false;
      }
      state.payType = payType;
      state.loading = true;
      state.formData.payType = payType;
      state.formData.open_id = localStorage.getItem('openid');
      post("wySubmit", state.formData)
        .then((response) => {
          console.log(response);
          if (payType === "alipay") {
            const div = document.createElement("div");
            /* 此处form就是后台返回接收到的数据 */
            div.innerHTML = response.data;
            document.body.appendChild(div);
            document.forms[0].submit();
          } else if (payType === "wxpay") {
            if (response.code != 200) {
              Dialog({ title: '提示', message: response.msg })
              return false
            }
            window.WeixinJSBridge.invoke('getBrandWCPayRequest', response.data,
              function (res) {
                console.log(res)
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  // 使用以上方式判断前端返回,微信团队郑重提示：
                  //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                }
              });
          }
        })
        .finally(() => {
          state.loading = false;
        });
    }
    return {
      ...toRefs(state),
      formRef,
      changeType,
      selectItems,
      submitOrder,
      kfUrl,
      postOrder,
      payTypeClose,
      gotoOrder,
      getIndexData,
    };
  },
};
</script>

<style lang="less">
.item_card {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.van-cell.van-field {
  background: none;
  border-bottom: 1px solid #d6c397;
  margin-top: 6px;
}
.van-radio__icon {
  height: 2.5em !important;
  border: none;
}
.van-grid-item__content {
  background: #f6f5f0;
  padding: 0px;
}
.van-button__text {
  color: #482d00;
}
.van-cell {
  align-items: center;
}
.van-radio__icon.van-radio__icon--round {
  align-items: center;
  display: flex;
}
.van-badge__wrapper.van-icon.van-cell__left-icon {
  display: flex;
  align-items: center;
}
</style>
